import React, {useEffect, useState} from "react";
import {Box, Container, Divider, Loader, Space, Title,} from "@mantine/core";
import {ICustomer} from "../../models/customer";
import {IClocking} from "../../models/clocking";
import {CustomerListItemCardMobile} from "./CustomerListItem";
import {StartClockingModal} from "./StartClockingModal";
import {EndClockingModal} from "./EndClockingModal";
import {useGetClockingsByUserIdQuery} from "../../redux/apis/clockingApi";
import {useKeycloak} from "@react-keycloak/web";
import {ClockingListItemCardMobile} from "./ClockingListItemCardMobile";
import {useGetCustomersNearbyQuery} from "../../redux/apis/customerApi";
import {useAppSelector} from "../../redux/hooks";

export const ClockingCreate = () => {
  const {keycloak} = useKeycloak();
  const [userId, setUserId] = React.useState<string>("");
  const [userName, setUserName] = React.useState<string>("");
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(null);
  const [clockingToEnd, setClockingToEnd] = useState<IClocking | null>(null);
  const {settings} = useAppSelector(s => s.settings);
  
  React.useEffect(() => {
    keycloak.loadUserProfile().then((u) => {
      setUserName(u.lastName + " " + u.firstName);
      setUserId(u.id);
    });
  }, []);
  
  const [lat, setLat] = useState<string>("");
  const [lon, setLon] = useState<string>("");
  
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        setLat(position.coords.latitude.toString());
        setLon(position.coords.longitude.toString());
      }, (error) => {
        console.log("Error getting geolocation: ", error);
      }, {
        enableHighAccuracy: true,
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);
  
  
  const {data: nearbyCustomers, isLoading} = useGetCustomersNearbyQuery({
      lat: lat,
      lon: lon,
      distanceInMeters: settings?.maxDistanceFromPosition ?? 0,
    },
    {
      skip: lat === "" || lon === "",
      refetchOnMountOrArgChange: true,
    });
  
  const {data: dataClockings, refetch} = useGetClockingsByUserIdQuery(userId);
  
  return !isLoading ? (
    <Container fluid
               sx={{
                 backgroundColor: "white",
                 borderRadius: 6,
                 padding: 12,
                 minHeight: "80vh",
               }}>
      {selectedCustomer != null && <StartClockingModal customer={selectedCustomer}
                                                       onClose={() => {
                                                         setSelectedCustomer(null);
                                                         refetch();
                                                       }}
                                                       userId={userId}
                                                       userName={userName}
                                                       submitEnabled={nearbyCustomers.some(nc => nc.id === selectedCustomer.id)}
      />}
      {clockingToEnd != null && <EndClockingModal clocking={clockingToEnd}
                                                  onClose={() => {
                                                    setClockingToEnd(null);
                                                    refetch();
                                                  }}/>}
      <Box>
        <Title mb={16}>Esegui timbratura</Title>
        <Space h="lg"/>
        {dataClockings?.length > 0 && (<>
            <Divider label={"Lavorazioni in corso"}/>
            <Space h="lg"/>
            {dataClockings
              .map((c) => (
                  <ClockingListItemCardMobile
                    id={c.id}
                    customer={c.customer.description}
                    completed={c.dateEnd !== null}
                    dateEnd={c.dateEnd ? new Date(c.dateEnd.toString()).toLocaleDateString() : null}
                    dateStart={`${new Date(c.dateStart.toString()).toLocaleDateString()} ${new Date(c.dateStart.toString()).toLocaleTimeString()}`}
                    user={c.userName}
                    onClick={() => setClockingToEnd(c)}
                    submitText={"Firma e concludi"}
                    submitEnabled={nearbyCustomers?.some(nc => nc.id === c.customer.id) ?? false}
                  />
                )
              )}
            <Space h="lg"/>
          </>
        )}
        <Divider label={"Clienti in zona"}/>
        <Space h="lg"/>
        {nearbyCustomers?.length === 0 && <Title align="center">Nessun cliente nelle vicinanze</Title>}
        {nearbyCustomers?.map((c) => (
          <CustomerListItemCardMobile customer={c} distance={0} disabled={dataClockings?.length > 0}
                                      onClick={() => setSelectedCustomer(c)}
                                      submitText={"Avvia lavorazione"}/>
        ))}
      
      </Box>
    
    </Container>
  ) : (<Loader color="blue"/>)
}
