import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {baseQueryWithReauth} from "./authApi";
import {ISettings} from "../../models/settingsParsed";

export const settingsApi = createApi({
  reducerPath: "settingsApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getSettings: builder.query<ISettings, void>({
      query: () => `/api/settings`,
      keepUnusedDataFor: 1,
    }),
    updateSettings: builder.mutation<ISettings, ISettings>({
      query: (settings) => ({
        url: `/api/Settings`,
        method: "PUT",
        body: JSON.stringify(settings.settings),
        contentType: "application/json",
      }),
    })
  }),
});

export const {
  useGetSettingsQuery,
  useUpdateSettingsMutation,
} = settingsApi;
