import {Badge, Button, Card, Group, Text} from "@mantine/core"
import React from "react"
import {IconCalendarTime, IconCategory, IconUser} from "@tabler/icons-react";


interface Props {
  id: string;
  customer: string;
  completed: boolean;
  dateEnd?: string;
  dateStart: string;
  user: string;
  onClick: (id: string) => void
  submitText?: string
  submitEnabled?: boolean
}

export const ClockingListItemCardMobile: React.FC<Props> = ({
                                                              id,
                                                              customer,
                                                              completed,
                                                              dateEnd,
                                                              dateStart,
                                                              user,
                                                              onClick,
                                                              submitText = "Visualizza",
                                                              submitEnabled = true
                                                            }: Props) => {
  
  return <Card shadow="md" radius='md' withBorder sx={{margin: '20px'}}>
    <Group position="apart" mt="md" mb="xs">
      <Text weight={700} size={18} w={"50%"}>{customer}</Text>
      <Badge color={dateEnd ? "green" : "gray"} variant="light" w={"40%"}>
        {completed ? "Completato" : "In corso"}
      </Badge>
    </Group>
    <hr/>
    <Text color="dimmed">
      <div style={{display: "flex"}}>
        {dateEnd ? <IconCalendarTime style={{marginRight: 16}}/> : <IconCategory style={{marginRight: 16}}/>}
        {dateEnd ? `Inizio: ${dateStart} - Fine: ${dateEnd}` : `Inizio: ${dateStart}`}
      </div>
      <div style={{display: "flex"}}>
        <IconUser style={{marginRight: 16}}/>
        {user}
      </div>
    </Text>
    
    <Button
      disabled={!submitEnabled}
      color='blue'
      variant={"light"}
      fullWidth sx={{marginTop: '20px'}}
      radius='md'
      onClick={() => onClick(id)}
    >{submitText}</Button>
  </Card>
}