import {configureStore} from "@reduxjs/toolkit";
import {customerApi} from "./apis/customerApi";
import {inspectionsApi} from "./apis/inspectionsApi";
import customerSlice from "./slices/customerSlice";
import inspectionSlice from "./slices/inspectionSlice";
import {proposteTecnicheApi} from "./apis/proposteTecnicheApi";
import proposteTecnicheSlice from "./slices/proposteTecnicheSlice";
import userSlice from "./slices/userSlice";
import {InventoryApi} from "./apis/inventoryApi";
import inventorySlice from "./slices/inventorySlice";
import {FirstContactApi} from "./apis/firstContactApi";
import firstContactSlice from "./slices/firstContactSlice";
import {vehicleApi} from "./apis/vehicleApi";
import vehiclesSlice from "./slices/vehicleSlice";
import {ClockingApi} from "./apis/clockingApi";
import clockingSlice from "./slices/clockingSlice";
import {settingsApi} from "./apis/settingsApi";
import settingsSlice from "./slices/settingsSlice";

export const store = configureStore({
  reducer: {
    [customerApi.reducerPath]: customerApi.reducer,
    [inspectionsApi.reducerPath]: inspectionsApi.reducer,
    [proposteTecnicheApi.reducerPath]: proposteTecnicheApi.reducer,
    [InventoryApi.reducerPath]: InventoryApi.reducer,
    [vehicleApi.reducerPath]: vehicleApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [FirstContactApi.reducerPath]: FirstContactApi.reducer,
    [ClockingApi.reducerPath]: ClockingApi.reducer,
    customers: customerSlice,
    inspections: inspectionSlice,
    inventory: inventorySlice,
    vehicle: vehiclesSlice,
    settings: settingsSlice,
    proposteTecniche: proposteTecnicheSlice,
    user: userSlice,
    firstContact: firstContactSlice,
    clocking: clockingSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      customerApi.middleware,
      inspectionsApi.middleware,
      proposteTecnicheApi.middleware,
      InventoryApi.middleware,
      vehicleApi.middleware,
      FirstContactApi.middleware,
      ClockingApi.middleware,
      settingsApi.middleware,
    ),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
