import React, {useEffect} from "react";
import {
  Box,
  Button,
  Col,
  Container,
  Divider,
  Grid,
  Loader,
  LoadingOverlay,
  Select,
  Space,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import {useGetClockingQuery, useUpdateClockingMutation} from "../../redux/apis/clockingApi";
import {useForm} from "@mantine/form";
import {showNotification} from "@mantine/notifications";
import {useRoute} from "wouter";
import {IconDownload} from "@tabler/icons-react";
import keycloak from "../../Keycloak";
import {useAppSelector} from "../../redux/hooks";

//TODO: make a reusable function, that's a duplicate
async function downloadFile(id: string, filename: string) {
  const response = await fetch(`/api/document/${id}/file`, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + keycloak.token
    }
  })
  
  if (!response.ok) {
    throw new Error('File download failed');
  }
  
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'file.pdf';
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}

export const ClockingDetail = () => {
  // @ts-ignore
  const [, {id}] = useRoute("/clockings/:id");
  
  const {data, refetch} = useGetClockingQuery(id);
  const {settings} = useAppSelector(s => s.settings);
  const [updateClocking, {isLoading}] = useUpdateClockingMutation();
  
  const form = useForm({
    initialValues: {
      dateStart: new Date(),
      dateEnd: null,
      customer: null,
      signature: null,
      service: "",
      serviceDescription: "",
      referente: "",
      photos: [],
      userId: "",
      userName: ""
    },
  });
  
  useEffect(() => {
    form.setValues({
      dateStart: data?.dateStart,
      dateEnd: data?.dateEnd,
      customer: data?.customer,
      signature: data?.signature,
      service: data?.service,
      serviceDescription: data?.serviceDescription,
      referente: data?.referente,
      photos: data?.photos,
      userId: data?.userId,
      userName: data?.userName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  
  return data ? (
    <Container fluid
               sx={{
                 backgroundColor: "white",
                 borderRadius: 6,
                 padding: 12,
                 minHeight: "80vh",
               }}>
      <form onSubmit={form.onSubmit(values => {
        updateClocking({
          id: id, ...values,
          dateEnd: values.dateEnd === null ? new Date().toISOString().replace("Z", "") : values.dateEnd,
          referente: `Completato in backoffice da: ${keycloak.tokenParsed.family_name} ${keycloak.tokenParsed.given_name}`,
          customerId: values?.customer.id,
        }).then(res => {
          // @ts-ignore
          if (res.error) {
            showNotification({
              message: "Qualcosa è andato storto! Contattare l'amministratore.",
              color: "red"
            })
          } else {
            showNotification({
              message: "Elemento aggiornato con successo!"
            })
            refetch();
          }
        })
      })}>
        <LoadingOverlay
          visible={isLoading}
          zIndex={1000}
        />
        <Box>
          <Title mb={16}>Dettaglio timbratura</Title>
          <Space h="lg"/>
          <Divider label={"Dati timbratura"}/>
          <Space h="lg"/>
          <Grid>
            <Col xs={12} sm={4}>
              <TextInput label="Cliente" value={data.customer.description} disabled/>
            </Col>
            <Col xs={12} sm={4}>
              <TextInput label="Data inizio" {...form.getInputProps("dateStart")}
                         value={`${new Date(data.dateStart).toLocaleDateString()} ${new Date(data?.dateStart).toLocaleTimeString()}`}
                         disabled/>
            </Col>
            <Col xs={12} sm={4}>
              <TextInput label="Data fine" {...form.getInputProps("dateEnd")}
                         value={data.dateEnd ? (`${new Date(data.dateEnd).toLocaleDateString()} ${new Date(data?.dateEnd).toLocaleTimeString()}`) : ""}
                         disabled/>
            </Col>
            <Col xs={12} sm={4}>
              <Select data={settings?.services ?? []} label="Servizio" required  {...form.getInputProps("service")}
                      disabled/>
            </Col>
            <Col xs={12} sm={4}>
              <TextInput label="Referente" {...form.getInputProps("referente")} disabled/>
            </Col>
            <Col xs={12} sm={4}>
              <label
                className={"mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2"}>Foto</label>
              <div>
                {data.photos?.length === 0 && <i>Nessuna foto</i>}
                {
                  data.photos?.map((photo) => (
                    <Button style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }} compact color={"blue"} variant={"light"} onClick={() => {
                      try {
                        downloadFile(photo.id, photo.fileName);
                      } catch (error) {
                        console.error(error);
                      }
                    }}><IconDownload size={16} style={{minWidth: 16}}/> Scarica foto
                    </Button>
                  ))
                }</div>
            </Col>
            <Col xs={12} sm={5}>
              <TextInput label="Eseguito da" {...form.getInputProps("userName")} disabled/>
            </Col>
            <Col xs={12}>
              <Textarea label="Descrizione" required {...form.getInputProps("serviceDescription")} disabled/>
            </Col>
          </Grid>
          <Grid>
            <Col xs={12} sm={5}>
              <div><label
                className={"mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2"}>Firma del
                referente</label></div>
              {data.signature === null && <i>Non è stata inserita alcuna firma</i>}
              {data.signature !== null &&
                  <img style={{maxWidth: '100%', height: "auto"}} src={data.signature} alt="Firma"/>}
            </Col>
          </Grid>
          <Space h="lg"/>
        </Box>
        <Space h="xl"/>
        {data.dateEnd === null && <Button type={"submit"}>Completa manualmente</Button>}
      </form>
    
    </Container>
  ) : (
    <Loader color="blue"/>
  )
}
