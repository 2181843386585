import React, {useState} from "react";
import 'dayjs/locale/it.js';
import {ActionIcon, Box, Popover, TextInput, Title} from "@mantine/core";
import {DataTable} from "../../components/table/DataTable";
import {useLocation} from "wouter";
import ResponsiveFullWidthContainer from "../../components/ResponsiveFullWidthContainer";
import useIsMobile from "../../hooks/useIsMobile";
import {useClocking} from "../../hooks/useClocking";
import {ClockingListItemCardMobile} from "./ClockingListItemCardMobile";
import {IconArrowsSort, IconCalendar, IconSortAscending, IconSortDescending, IconUser} from "@tabler/icons-react";
import {DatePicker} from "@mantine/dates";
import {useAppSelector} from "../../redux/hooks";

const TITLE_HEIGHT = 80;

export const ClockingList: React.FC = () => {
  const isMobile = useIsMobile();
  const {settings} = useAppSelector(s => s.settings);
  
  const {
    items,
    pages,
    currentPage,
    tableFilters,
    setTableFilters,
    setCurrentPage,
    filter,
    setFilter,
    setOrderColumn,
    orderColumnRef,
    orderDescending,
  } = useClocking();
  
  const [showOrderPopover, setShowOrderPopover] = useState<boolean>(false);
  
  const mapItems = items.map((item) => ({
    ...item,
    service: settings?.services?.find(x => x.value === item.service)?.label || item.service,
    dateStart: `${new Date(item?.dateStart).toLocaleDateString()} ${new Date(item?.dateStart).toLocaleTimeString()}`,
    dateEnd: `${new Date(item.dateEnd).toLocaleDateString()} ${new Date(item?.dateEnd).toLocaleTimeString()}`,
  }));
  
  const [, setLocation] = useLocation();
  
  const columns = [
    {
      accessor: "id",
      visible: false,
    },
    {
      accessor: "dateStart",
      label: "Data inizio",
      visible: true,
      searchable: false
    },
    {
      accessor: "dateEnd",
      label: "Data fine",
      visible: true,
      searchable: false
    },
    {
      accessor: "customer",
      label: "Cliente",
      visible: true,
    },
    {
      accessor: "service",
      label: "Servizio",
      visible: true,
    },
    {
      accessor: "completed",
      label: "Completato",
      visible: true,
      searchable: false
    },
    {
      accessor: "user",
      label: "Utente",
      visible: true,
    },
  ];
  return (
    <ResponsiveFullWidthContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: TITLE_HEIGHT,
        }}
        mb={8}
      >
        <Title order={2} mb={8}>
          Gestione timbrature
        </Title>
      </Box>
      {!isMobile ?
        <Box sx={{height: `calc(100% - ${TITLE_HEIGHT}px)`}}>
          <DataTable
            tableFilters={tableFilters}
            setTableFilters={setTableFilters}
            onSearch={(searchValue) => setFilter(searchValue)}
            onSort={(columnRef) => {
              setOrderColumn(columnRef);
            }}
            sortingColumn={orderColumnRef}
            sortingDesc={orderDescending}
            header={columns}
            data={mapItems}
            pages={pages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onClick={(id: string) => setLocation(`/clockings/${id}`)}
          />
        </Box> : <Box sx={{marginBottom: TITLE_HEIGHT + 50}}>
          <div style={{
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <IconCalendar/>
            <DatePicker w={"80%"} locale={'it'} sx={{margin: 8, marginRight: 36}} placeholder={"Filtra per data"}
                        onChange={(value) => {
                          setTableFilters({
                            columnRef: "dateStart",
                            value: value?.toLocaleDateString(),
                          })
                        }}/>
          </div>
          <div style={{
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <IconUser/>
            
            <TextInput
              w={"80%"}
              placeholder='Filtra per utente o azienda'
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              sx={{margin: 8}}
            />
            
            <Popover opened={showOrderPopover} onChange={setShowOrderPopover}>
              <Popover.Target>
                <ActionIcon variant={"filled"} color={"blue"}><IconArrowsSort
                  onClick={() => setShowOrderPopover((o) => !o)}/></ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <div>
                  {columns.map(c => {
                    const isOrdering = orderColumnRef === c.accessor
                    return c.visible && (
                      <div style={{display: "flex", marginBottom: 8}} onClick={() => setOrderColumn(c.accessor)}>
                        <ActionIcon>
                          {isOrdering && (orderDescending ? <IconSortDescending/> : <IconSortAscending/>)}
                        </ActionIcon>
                        {c.label}
                      </div>
                    )
                  })}
                </div>
              </Popover.Dropdown>
            </Popover>
          </div>
          {mapItems.map((item) => (
            <ClockingListItemCardMobile
              key={item.id}
              id={item.id}
              customer={item.customer}
              completed={item.completed}
              dateEnd={item.dateEnd}
              dateStart={item.dateStart}
              user={item.user}
              onClick={(id) => setLocation(`/clockings/${id}`)}/>
          ))}
        </Box>
      }
    </ResponsiveFullWidthContainer>
  );
};
