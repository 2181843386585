import * as React from "react";
import {useEffect, useState} from "react";
import FullWidthContainer from "../components/FullWidthContainer";
import {useForm} from "@mantine/form";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {useGetSettingsQuery, useUpdateSettingsMutation} from "../redux/apis/settingsApi";
import {setSettings} from "../redux/slices/settingsSlice";
import {Box, Button, Col, Divider, Grid, LoadingOverlay, MultiSelect, NumberInput, Space, Title} from "@mantine/core";
import {SettingsParsed} from "../models/settingsParsed";

const Settings = () => {
  const dispatch = useAppDispatch();
  const {data} = useGetSettingsQuery();
  const {settings} = useAppSelector(s => s.settings);
  const [updateSettings, {isLoading}] = useUpdateSettingsMutation();
  
  const [operators, setOperators] = useState(settings?.operators ?? []);
  const [units, setUnits] = useState(settings?.units ?? []);
  const [productCategories, setProductCategories] = useState(settings?.categories ?? []);
  const [trackingDistance, setTrackingDistance] = useState(settings?.maxDistanceFromPosition ?? 0);
  const [services, setServices] = useState(settings?.services ?? []);
  
  useEffect(() => {
    if (!data) return;
    dispatch(setSettings(data))
  }, [data])
  
  useEffect(() => {
    if (!settings) return;
    setOperators(settings?.operators ?? []);
    setUnits(settings?.units ?? []);
    setProductCategories(settings?.categories ?? []);
    setTrackingDistance(settings?.maxDistanceFromPosition ?? 0);
    setServices(settings?.services ?? []);
  }, [settings])
  
  const form = useForm({
    initialValues: {},
  });
  return (
    <FullWidthContainer>
      <form onSubmit={form.onSubmit(values => {
        const settingsParsed: SettingsParsed = {
          operators: operators,
          units: units,
          categories: productCategories,
          maxDistanceFromPosition: trackingDistance,
          services: services
        }
        
        updateSettings({
          settings: JSON.stringify(settingsParsed)
        }).then(() => {
          window.location.reload();
        });
      })}>
        <LoadingOverlay
          visible={isLoading}
          zIndex={1000}
        />
        <Box>
          <Title mb={16}>Impostazioni</Title>
          <Space h="lg"/>
          <Divider label={"Impostazioni magazzino"}/>
          <Space h="lg"/>
          <Grid>
            <Col xs={12} sm={4}>
              <MultiSelect
                label="Operatori"
                data={operators}
                placeholder="Seleziona operatori"
                searchable
                creatable
                value={operators.map(o => o.value)}
                onChange={(value) => setOperators(value.map(o => ({value: o, label: o})))}
                getCreateLabel={(query) => `+ Aggiungi ${query}`}
                onCreate={(query) => {
                  const item = {value: query, label: query};
                  setOperators((current) => [...current, item]);
                  return item;
                }}
              />
            </Col>
          </Grid>
          <Grid>
            <Col xs={12} sm={4}>
              <MultiSelect
                label="Categorie"
                data={productCategories}
                placeholder="Seleziona categorie"
                searchable
                creatable
                value={productCategories.map(o => o.value)}
                onChange={(value) => setProductCategories(value.map(o => ({value: o, label: o})))}
                getCreateLabel={(query) => `+ Aggiungi ${query}`}
                onCreate={(query) => {
                  const item = {value: query, label: query};
                  setProductCategories((current) => [...current, item]);
                  return item;
                }}
              />
            </Col>
            <Col xs={12} sm={4}>
              <MultiSelect
                label="Unità di misura"
                data={units}
                placeholder="Seleziona unità"
                searchable
                creatable
                value={units.map(o => o.value)}
                onChange={(value) => setUnits(value.map(o => ({value: o, label: o})))}
                getCreateLabel={(query) => `+ Aggiungi ${query}`}
                onCreate={(query) => {
                  const item = {value: query, label: query};
                  setUnits((current) => [...current, item]);
                  return item;
                }}
              />
            </Col>
          </Grid>
          <Space h="lg"/>
          <Divider label={"Impostazioni timbratura"}/>
          <Space h="lg"/>
          <Grid>
            <Col xs={12} sm={4}>
              <MultiSelect
                label="Servizi"
                data={services}
                placeholder="Seleziona servizi"
                searchable
                creatable
                value={services.map(o => o.value)}
                onChange={(value) => setServices(value.map(o => ({value: o, label: o})))}
                getCreateLabel={(query) => `+ Aggiungi ${query}`}
                onCreate={(query) => {
                  const item = {value: query, label: query};
                  setServices((current) => [...current, item]);
                  return item;
                }}
              />
            </Col>
            <Col xs={12} sm={4}>
              <NumberInput value={trackingDistance} label="Distanza massima dalla posizione"
                           onChange={setTrackingDistance}/>
            </Col>
          </Grid>
        </Box>
        <Space h="xl"/>
        
        <Button type="submit">Salva</Button>
      </form>
    </FullWidthContainer>
  );
};

export default Settings;
