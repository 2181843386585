import React, {useRef} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {IconTrash} from "@tabler/icons-react";
import {useViewportSize} from "@mantine/hooks";

interface Props {
  setSignature: (content: string) => void;
}

const SignaturePad = ({setSignature}: Props) => {
  const sigRef = useRef();
  const {height, width} = useViewportSize();
  
  const handleSignatureEnd = () => {
    // @ts-ignore
    setSignature(sigRef.current.toDataURL());
  }
  const clearSignature = () => {
    // @ts-ignore
    sigRef.current.clear();
    setSignature(null);
  }
  
  return <div
    style={{
      border: '1px solid black',
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: width,
      height: height,
      background: "white",
      overflow: "hidden"
    }}>
    <SignatureCanvas
      penColor="black"
      canvasProps={{width: width, height: height}}
      ref={sigRef}
      onEnd={handleSignatureEnd}
    />
    <IconTrash size={24} onClick={clearSignature}
               style={{cursor: 'pointer', position: 'absolute', right: 10, top: 10}}/>
  </div>
};

export default SignaturePad;