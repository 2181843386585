import React, {useEffect, useState} from "react";
import {ActionIcon, Center, Group, Popover, Text, TextInput, UnstyledButton,} from "@mantine/core";
import {IconChevronDown, IconChevronUp, IconFilter, IconSearch, IconSelector,} from "@tabler/icons-react";
import {useDisclosure} from "@mantine/hooks";
import {useStyles} from "./DataTable";

interface TableHeaderProps {
  children: React.ReactNode;
  reversed?: boolean;
  sorted?: boolean;
  columnRef?: string;
  filter?: string;
  onSort?: () => void;
  onFilter?: (value: string, columnRef: string) => void;
  searchable?: boolean;
}

export function TableHeader({
                              children,
                              reversed,
                              sorted,
                              columnRef,
                              filter,
                              onSort,
                              onFilter,
                              searchable = true
                            }: TableHeaderProps) {
  const [opened, {close, open}] = useDisclosure(false);
  const [filterValue, setFilterValue] = useState("");
  
  function submit() {
    onFilter(filterValue, columnRef);
    close();
  }
  
  useEffect(() => {
    if (opened) {
      setFilterValue(filter);
    } else {
      setFilterValue("");
    }
  }, [opened]);
  
  const {classes} = useStyles();
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <th
      className={classes.th}
      style={{
        minWidth: 180,
      }}
    >
      <Popover
        position="bottom"
        withArrow
        shadow="md"
        opened={!searchable ? false : opened}
        onClose={close}
        transition="pop"
      >
        <Popover.Target>
          <UnstyledButton
            onClick={onFilter ? open : null}
            className={classes.control}
          >
            <Group position="apart">
              <Text weight={500} size="sm">
                {children}
              </Text>
              {onSort && (
                <Center
                  className={classes.icon}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSort();
                  }}
                >
                  {filter && <IconFilter size={16} stroke={1.5}/>}
                  <Icon size={16} stroke={1.5}/>
                </Center>
              )}
            </Group>
          </UnstyledButton>
        </Popover.Target>
        <Popover.Dropdown>
          <Text size="sm" style={{display: "flex"}}>
            <TextInput
              placeholder="Inserire filtro"
              value={filterValue}
              onChange={(event) => setFilterValue(event.currentTarget.value)}
              onKeyDown={(e) => e.key === "Enter" && submit()}
            />
            <Center>
              <ActionIcon onClick={submit}>
                <IconSearch/>
              </ActionIcon>
            </Center>
          </Text>
        </Popover.Dropdown>
      </Popover>
    </th>
  );
}
