import {Badge, Button, Card, Group, Text} from "@mantine/core"
import React from "react"
import {IconHome} from "@tabler/icons-react";
import {ICustomer} from "../../models/customer";

interface Props {
  customer: ICustomer
  distance: number
  disabled?: boolean
  onClick: (id: string) => void
  submitText?: string
}

export const MAX_DISTANCE = 10

export const CustomerListItemCardMobile: React.FC<Props> = ({
                                                              customer,
                                                              distance,
                                                              disabled,
                                                              onClick,
                                                              submitText = "Visualizza",
                                                            }: Props) => {
  
  return <Card shadow="md" radius='md' withBorder sx={{margin: '20px'}}>
    <Group position="apart" mt="md" mb="xs">
      <Text weight={700} size={18} w={"50%"}>{customer.description}</Text>
    </Group>
    <Group position="apart" mt="md" mb="xs">
      <Badge color={distance > MAX_DISTANCE ? "red" : "green"} variant="light" w={"100%"}>
        {distance > MAX_DISTANCE ? "Sei troppo distante" : "Sei vicino"}
      </Badge>
    </Group>
    <hr/>
    <Text color="dimmed">
      <div style={{display: "flex"}}>
        <IconHome style={{marginRight: 16}}/>
        {customer.city} {customer.province} {customer.zipCode}
      </div>
      <div style={{display: "flex"}}>
        <IconHome style={{marginRight: 16}}/>
        {customer.address}
      </div>
    </Text>
    
    <Button
      color='blue'
      disabled={disabled || (distance > MAX_DISTANCE)}
      variant={"light"}
      fullWidth sx={{marginTop: '20px'}}
      radius='md'
      onClick={() => onClick(customer.id)}
    >{submitText}</Button>
  </Card>
}