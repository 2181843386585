import {useForm} from "@mantine/form";
import React from "react";
import {useAddInventoryMovementMutation} from "../../../redux/apis/inventoryApi";
import {Box, Button, LoadingOverlay, Modal, NumberInput, Select, TextInput, Title,} from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import {useAppSelector} from "../../../redux/hooks";

interface formProps {
  name: string;
  id: string;
  barcode: string;
  actualQuantity: number;
  username: string;
}

interface Props {
  isOpen: boolean;
  formProps: formProps;
  setIsOpen: (value: boolean) => void;
  resetBarcode: () => void;
  download?: boolean;
}

const UploadItem: React.FC<Props> = ({
                                       isOpen,
                                       formProps,
                                       setIsOpen,
                                       resetBarcode,
                                       download,
                                     }: Props) => {
  const [addInventoryMovement, {isLoading: loadingAddMovement}] =
    useAddInventoryMovementMutation();
  const {settings} = useAppSelector(s => s.settings);
  
  const form = useForm({
    initialValues: {
      quantity: 0,
      username: formProps.username,
      assignedTo: undefined,
    },
  });
  
  const handleIsOpen = (value: boolean): void => {
    setIsOpen(value);
    resetBarcode();
  };
  
  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        handleIsOpen(!isOpen);
      }}
    >
      <LoadingOverlay visible={loadingAddMovement} zIndex={1000}/>
      
      <Box>
        <Title order={3} mb={8} sx={{height: 45, textAlign: "center"}}>
          {download ? "Scarica prodotto" : "Carica Prodotto"}
        </Title>
        <form
          onSubmit={form.onSubmit(({quantity, assignedTo}) => {
            if (download && quantity > formProps?.actualQuantity) {
              showNotification({
                message: "Non è possibile scaricare più di quanto presente!",
                color: "red"
              })
              
              return
            }
            
            if (assignedTo === undefined) {
              showNotification({
                message: "Seleziona un operatore!",
                color: "red"
              })
              
              return
            }
            
            let newQuantity = (quantity: number): number => {
              return download ? quantity * -1 : quantity;
            };
            
            addInventoryMovement({
              itemId: formProps.id,
              body: {
                quantity: newQuantity(quantity),
                username: formProps.username,
                assignedTo: download ? assignedTo : "",
              },
            })
              .then(() => {
                showNotification({
                  message: "Quantità aggiornata!"
                })
                handleIsOpen(false);
              })
              .catch(() => {
                console.error("Something went wrong");
              });
          })}
        >
          <TextInput label="Nome" defaultValue={formProps?.name} disabled/>
          <TextInput
            label="Barcode"
            defaultValue={formProps?.barcode}
            disabled
          />
          <p>Quantità presente: {formProps?.actualQuantity}</p>
          
          <NumberInput
            label={
              download ? "Quantità da scaricare" : "Quantità da aggiungere"
            }
            min={0}
            {...form.getInputProps("quantity")}
          />
          
          <Select sx={{
            margin: "5% 0%",
          }}
                  data={settings?.operators ?? []}
                  label="Consegnato a"
                  required
                  {...form.getInputProps("assignedTo")}
          />
          
          <Button
            type="submit"
            size="sm"
            sx={{
              margin: "5% 0%",
            }}
          >
            {download ? "Scarica" : "Aggiungi"}
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default UploadItem;
