import {createSlice} from "@reduxjs/toolkit";
import {SettingsParsed} from "../../models/settingsParsed";

export const DEFAULT_PAGE_LIMIT = 100;

// Define a type for the slice state

interface IISettingsState {
  settings: SettingsParsed;
}

// Define the initial state using that type
const initialState: IISettingsState = {
  settings: {},
};

// @ts-ignore
export const SettingsSlice = createSlice({
  name: "Settings",
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings = JSON.parse(action.payload?.settingsJson ?? "{}");
    },
  },
});

export const {
  setSettings
} = SettingsSlice.actions;

export default SettingsSlice.reducer;
